:root {
	--gray: rgba(0, 0, 0, 0.3);
	--secondary: rgb(215, 111, 78);
	--ol-blue: rgba(0, 60, 136, 0.3);
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 5s linear;
	}
}

.App-header {
	background-color: #b6ccfa;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	0% {
		transform: rotate(30deg);
	}
	25% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(-30deg);
	}
	75% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(30deg);
	}
}

.ol-map {
	position: fixed;
	height: 100vh;
	width: 100vw;
	min-width: 600px;
	min-height: 600px;
	margin: 0;
}
.ol-control {
	position: absolute;
	right: 0;
	margin: 4px;
	padding: 2px;
	border-radius: 4px;
	background-color: rgba(255, 255, 255, 0.4);
	z-index: 100;
}

/* mui data grid */
.MuiDataGrid-columnHeaders {
	min-height: 24px;
	max-height: 38px;
}

/* chrome,safari,opera */
::-webkit-scrollbar {
	width: 9px;
	height: 9px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	border-radius: 3px;
	background: var(--gray);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: darkgray;
}

#legend-panel ::-webkit-scrollbar,
#legend-panel ::-webkit-scrollbar-thumb {
	width: 5px;
	border-radius: 0;
	background: lightgray;
}
#legend-panel ::-webkit-scrollbar-track {
	background: #fff;
}

/* OPEN-LAYERS */
.ol-mouse-control {
	position: absolute;
	right: 0.5em;
	bottom: 0.3em;
	width: 160px;
	line-height: 0.9;
	font-size: 12px;
	padding: 4px;
	text-align: center;
	color:white;
	background: var(--ol-blue);
}

.ol-mouse-control:hover {
	background: var(--ol-blue);
}

.ol-scale-line {
	left: auto;
	bottom: auto;
	position: absolute;
	right: 12em;
	bottom: 0.5em;
}

.ol-scale-line-inner {
	height: 11px;
	color:white;
}

.MuiDataGrid-columnHeaderTitle {
	font-weight: bold !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
	border-color: rgba(0, 0, 0, 0.23) !important;
}

/* .MuiPaper-root .MuiButtonBase-root {
	padding-left: 2 !important;
} */

.MuiAutocomplete-root .MuiOutlinedInput-root {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.measureButtonStyles {
	height: fit-content;
	padding: 7px 9px;
	/* border-radius: 50%; */
	border: none !important;
	cursor: pointer;
	/* outline: none; */
}

.measureButtonStyles:last-child {
	border-radius: 0 0 8px 8px;
}

.measureToggleGroupStyles {
	display: flex;
	flex-direction: column;
}

.ol-overlay-container {
	background-color: hsla(0, 0%, 100%, 0.7);
	border-radius: 5px;
	padding: 0 0.5rem;
}

.MuiDataGrid-panel .MuiDataGrid-paper {
	max-height: 200px;
}
.MuiDataGrid-menuList {
	padding-left: 8px !important;
}


  
  
